
import Vue from 'vue'
import { metadata } from '~/assets/ts/utils/metadata'
import { TailwindBreakpoint } from '~/assets/ts/enums'
import { qsBool } from '~/assets/ts/utils/params'
import base from '~/components/site/nav/Base.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import SearchInput from '~/components/_general/SearchInput.vue'
import SiteUserDropdown from '~/components/site/user/Dropdown.vue'
import SiteNavContents from '~/components/site/nav/Contents.vue'
import SiteHeaderLogo from '~/components/site/HeaderLogo.vue'
import { SitePreferences } from '~/store/site'

export default Vue.extend({
  name: 'SiteStructure',
  components: {
    SiteHeaderLogo,
    SiteNavContents,
    SiteUserDropdown,
    SearchInput,
    SaIcon,
  },
  props: {
    baseUrl: {
      type: String,
      default: '/',
    },
    headerClasses: {
      type: String,
      default: 'bg-gray-700 dark:bg-gray-800',
    },
    navDefaultClosed: {
      type: Boolean,
    },
    includeSearch: {
      type: Boolean,
    },
    dashboard: {
      type: Boolean,
    },
    includeNav: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const prefs = this.$store.getters['site/preferences'] as SitePreferences
    return {
      mounted: false,
      navOpenClass: prefs.showDesktopNav,
      navOpenMobile: false,
      mobileSearchOpen: false,
      searchQuery: this.$route.query.q ?? '',
      windowWidth: 0,
      searching: false,
    }
  },
  head(this: any) {
    const navClass = this.navOpenClass ? 'open' : 'closed'
    return metadata(this, {
      bodyAttrs: {
        class: `site-body site-nav-${navClass}${
          this.modalOpen ? ' modal-open' : ''
        }${this.isMobileOrTablet ? ' mobile' : ''}`,
      },
    })
  },
  computed: {
    preferences(): SitePreferences {
      return this.$store.getters['site/preferences']
    },
    navOpenDesktop(): boolean {
      return this.preferences.showDesktopNav
    },
    base() {
      return base
    },
    isMobileOrTablet(): boolean {
      return this.$device.isMobileOrTablet
    },
    modalOpen(): boolean {
      return this.$store.getters['site/modalOpen']
    },
    renderNav(): boolean {
      return this.includeNav && !this.popout
    },
    showMobileSearch(): boolean {
      return this.includeSearch && this.mobileSearchOpen
    },
    popout(): boolean {
      return qsBool(this, 'popout')
    },
    routeQuery(): string {
      return this.$route.query.q as string
    },
    mainDynamicStyles(): string {
      if (!this.renderNav) return 'pl-0'
      if (!this.mounted) {
        if (!this.navOpenDesktop) return ''
        return 'md:pl-60'
      }
      return this.navOpen ? 'pl-0 sm:pl-60' : 'pl-0'
    },
    navDynamicStyles(): string {
      if (!this.mounted) {
        if (!this.navOpenDesktop) return '-left-full md:w-60'
        return '-left-full md:left-0 md:w-60'
      }
      return this.navOpen ? 'left-0 w-full sm:w-60' : '-left-full sm:-w-60'
    },
    navOpen(): boolean {
      if (!this.renderNav) return false
      return this.mobile ? this.navOpenMobile : this.navOpenDesktop
    },
    mobile(): boolean {
      return this.windowWidth < TailwindBreakpoint.md
    },
  },
  watch: {
    routeQuery() {
      if (!this.includeSearch) return
      this.searchQuery = this.routeQuery
    },
    navOpen() {
      this.$store.commit('site/SET_NAV_OPEN', this.navOpen)
    },
  },
  mounted() {
    this.mounted = true
    window.addEventListener('resize', this.resize)
    this.resize()
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    updatePreferences(preferences: Partial<SitePreferences>) {
      this.$store.dispatch('site/updateSitePreferences', preferences)
    },
    toggleSearch() {
      this.mobileSearchOpen = !this.mobileSearchOpen
      if (this.mobileSearchOpen) {
        const input = this.$refs.search as any
        input.focusInput()
      }
    },
    closeMobileNav() {
      if (this.mobile) {
        this.navOpenMobile = false
      }
    },
    navPageOpened() {
      this.closeMobileNav()
    },
    resize() {
      this.windowWidth = window.innerWidth
      this.setBodyNavClass()
    },
    setBodyNavClass() {
      this.navOpenClass = this.navOpen
    },
    toggleNav() {
      if (this.mobile) {
        this.navOpenMobile = !this.navOpenMobile
        if (this.navOpenMobile) {
          const dropdown = this.$refs.userDropdown as Vue
          dropdown.$emit('close')
        }
      } else {
        this.updatePreferences({
          showDesktopNav: !this.navOpenDesktop,
        })
      }
    },
    navAnimationEnded() {
      this.setBodyNavClass()
    },
    getSearchResults() {
      this.$navigateTo({
        path: '/search',
        query: { q: this.searchQuery },
      })
    },
  },
})
